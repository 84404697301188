import * as React from "react"
import { _h2, _h3, _description } from "../headline.styles"
import styled from "styled-components"

const _tag = styled.a`
    border-radius: 14.5px;
    background-color: #e1edff;

    font-size: 12px;
    line-height: normal;
    letter-spacing: 0.3px;
    color: #1d253c;

    display: inline-block;
    padding: 4px 15px;
    padding: 4px 10px;
    margin: 2px 2.5px;

    &:hover {
        border: none;
    }
`

const _postCardTag = styled.div`
    font-size: 12px;
    line-height: normal;
    letter-spacing: 0.08px;
    color: #646c7a;
`

interface TagProps {
    tag: string
    href?: string
}

export const Tag: React.FC<TagProps> = ({ tag, href }) => {
    return <_tag href={href}>{tag}</_tag>
}

export const PostCardTag: React.FC<TagProps> = ({ tag }) => {
    return <_postCardTag>{tag}</_postCardTag>
}
