import * as React from "react"
import { _Blockquote } from "../../blockquote.styles"
import { _ImageLink } from "../../links.styles"
import PlaneIcon from "../../../../images/footer/newsletter/plane.png"
import PlaneIcon2x from "../../../../images/footer/newsletter/plane@2x.png"
import PlaneIcon3x from "../../../../images/footer/newsletter/plane@3x.png"
import { _EmailInput, _submitImageButton, _formContainer } from "./form.styles"
import * as yup from "yup"
import { Formik } from "formik"
import { onSubmit } from "./Submit"

interface FormContainerProps {
    emailPlaceholder: string
}

const newsletterInitialValues = {
    email: ""
}

const newsletterValidationSchema = yup.object().shape({
    email: yup
        .string()
        .email("E-mail is not valid!")
        .required("E-mail is required!")
})

interface NewsletterFormProps {
    isSubmitting: boolean
    errors: any
    handleChange: any
    handleSubmit: any
    submitCount: number
    values: any
    status?: string
    emailPlaceholder: string
}

const NewsletterForm = (props: NewsletterFormProps) => {
    const { handleChange, handleSubmit, values, status, emailPlaceholder } = props

    return (
        <>
            <form>
                <_formContainer>
                    <_EmailInput
                        type="text"
                        name="email"
                        placeholder={emailPlaceholder}
                        onChange={handleChange}
                        value={values.email}
                    />

                    <_submitImageButton onClick={handleSubmit} type="submit">
                        <img src={PlaneIcon} srcSet={`${PlaneIcon2x} 2x, ${PlaneIcon3x} 3x`} />
                    </_submitImageButton>
                </_formContainer>
            </form>

            {status === "success" && (
                <_Blockquote color={"var(--cool-green)"} fontSize={14} lineHeight={1.43} opacity={0.4}>
                    Thanks for subscription!
                </_Blockquote>
            )}
        </>
    )
}

export const FormContainer: React.FC<FormContainerProps> = ({ emailPlaceholder }) => {
    const formProps = {
        emailPlaceholder
    }

    return (
        <Formik
            render={formikProps => <NewsletterForm {...formikProps} {...formProps} />}
            validationSchema={newsletterValidationSchema}
            initialValues={newsletterInitialValues}
            onSubmit={onSubmit}
        />
    )
}
