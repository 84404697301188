import * as React from "react"
import styled from "styled-components"
import { _linkButton, _linkBlockButton } from "./index"

type NavButtonProps = {
    to: string
    title?: string
    className?: string
}

const _navMenuButton = styled(_linkButton)`
    padding: 6px 18px;
`
const _navBlockMenuButton = styled(_linkBlockButton)`
    padding: 6px 18px;
`

export const NavButton: React.FC<NavButtonProps> = ({ to, title, children, className }) => {
    return (
        <_navMenuButton className={className} to={to} title={title}>
            {children}
        </_navMenuButton>
    )
}

export const NavBlockButton: React.FC<NavButtonProps> = ({ to, title, children }) => {
    return (
        <_navBlockMenuButton to={to} title={title}>
            {children}
        </_navBlockMenuButton>
    )
}
