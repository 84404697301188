import * as React from "react"
import styled from "styled-components"

export const _videoWrapper = styled.div`
    position: relative;
    padding-top: 56.25%;

    iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
`

interface VideoProps {
    videoSrcURL: string
    videoTitle?: string
}

export const EmbededVideo: React.FC<VideoProps> = ({ videoSrcURL, videoTitle }) => (
    <_videoWrapper>
        <iframe
            src={videoSrcURL}
            title={videoTitle}
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            frameBorder="0"
        />
    </_videoWrapper>
)
