import * as React from "react"

import { Row } from "react-flexbox-grid"
import styled from "styled-components"

interface PersonProps {
    name: string
    title: string
    image?: string
    isMobile: boolean
    className?: string
    containerClassName?: string
    darkFont?: boolean
}

const _avatar = styled.img`
    width: 60px;
    height: 60px;
    object-fit: contain;
`

const _userName = styled.figure`
    font-family: Nunito;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: 0.1px;
    color: #ff8155;
    margin-inline-start: 0;
    margin-inline-end: 0;
    margin-block-end: 0em;

    &.dark {
        color: var(--dark);
        font-weight: bold;
    }
`

const _userTitle = styled.figure`
    font-family: Nunito;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: normal;
    color: var(--white);
    margin-inline-start: 0;
    margin-inline-end: 0;
    margin-block-start: 0em;

    &.dark {
        color: var(--slate-grey);
    }
`

const _signature = styled.div`
    &.leftAlign {
        text-align: left;
    }
`

const _flexContainer = styled.div`
    display: flex;
    align-items: center;
    margin-left: 0;

    &.compresed {
        margin-left: 0;
        padding: 0;
    }

    @media only screen and (min-width: 992px) {
        margin-left: -50px;
        &.compresed {
            padding: 0 20px;
        }
    }
`

const _avatarContainer = styled.div`
    margin-right: 20px;
`

export const PersonSignature: React.FC<PersonProps> = ({
    name,
    title,
    image,
    className,
    containerClassName,
    darkFont = false
}) => {
    const conditionalDarkFont = (): string => {
        if (darkFont) return "dark"
        return ""
    }

    const desktopSignature = (
        <Row>
            <_flexContainer className={containerClassName}>
                <_avatarContainer>
                    <_avatar src={image} />
                </_avatarContainer>

                <div>
                    <_signature className={className}>
                        <_userName className={conditionalDarkFont()}>{name}</_userName>
                        <_userTitle className={conditionalDarkFont()}>{title}</_userTitle>
                    </_signature>
                </div>
            </_flexContainer>
        </Row>
    )

    return desktopSignature
}
