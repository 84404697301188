import React from "react"
import BwIcon from "../../images/bw.png"
import FwIcon from "../../images/fw.png"

interface SliderArrowProps {
    className?: string
    to: string
    onClick?: any
}

export const SliderArrow: React.FC<SliderArrowProps> = ({ className, to, onClick }) => {
    return (
        <button
            type="button"
            onClick={onClick}
            className={`button button--text button--icon ${className}`}
            aria-label={to}>
            <img src={to === "prev" ? BwIcon : FwIcon} className="icon" />
        </button>
    )
}
