import styled from "styled-components"

interface SectionProps {
    marginTop?: number | string
    marginTopSm?: number | string
    marginBottom?: number | string
    marginBottomSm?: number | string
}

export const _Section = styled.div<SectionProps>`
    margin-top: ${props => (props.marginTopSm ? props.marginTopSm : "2")}em;
    margin-bottom: ${props => (props.marginBottom ? props.marginBottom : "2")}em;
    position: relative;

    @media only screen and (min-width: 992px) {
        margin-top: ${props => (props.marginTop ? props.marginTop : "5")}em;
        margin-bottom: ${props => (props.marginBottom ? props.marginBottom : "5")}em;
    }
`

export const _SectionNoMargin = styled(_Section)`
    margin: 0;

    @media only screen and (min-width: 992px) {
        margin: 0;
    }
`

export const _OverflowHiddenSectionNoMargin = styled(_SectionNoMargin)`
    overflow: hidden;
`
