import * as React from "react"
import styled from "styled-components"
import { Col, Row } from "react-flexbox-grid"
import { _Blockquote } from "../shared/blockquote.styles"
import { PersonSignature } from "../shared"
import quote from "../../images/v2/quote-orange.svg"

const TestimonialWrapper = styled.div`
    padding-left: 1em;
    padding-right: 1em;
    max-width: 600px
    margin: auto;
`

interface TestimonialItemProps {
    body?: string | JSX.Element
    name: string
    title: string
    image: string
    isMobile: boolean
}

const _quoteImage = styled.img`
    position: absolute;
    left: -15px;
    top: 20px;
    z-index: -1;

    fill: red;

    @media only screen and (min-width: 992px) {
        left: -45px;
    }
`

export const TestimonialItem: React.FC<TestimonialItemProps> = ({ body, name, title, image, isMobile }) => {
    return (
        <TestimonialWrapper>
            <Col lg={7} md={12} style={{ margin: "auto" }}>
                {body && (
                    <Row>
                        <_Blockquote
                            color={"var(--black)"}
                            fontSize={24}
                            fontSizeSm={24}
                            lineHeight={1.5}
                            marginTop={"0"}
                            marginBottom={"0"}
                            align={isMobile ? "center" : "left"}>
                            <_quoteImage src={quote} />
                            {body}
                        </_Blockquote>
                    </Row>
                )}
                <PersonSignature image={image} name={name} title={title} isMobile={isMobile} darkFont />
            </Col>
        </TestimonialWrapper>
    )
}
