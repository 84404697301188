import React from "react"

export const useForceUpdateState = () => {
    const [, updateState] = React.useState()
    const forceUpdate = React.useCallback(() => updateState({}), [])

    React.useEffect(() => forceUpdate, [forceUpdate])

    return updateState
}
