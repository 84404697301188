import * as React from "react"
import { _h3, _h2 } from "./headline.styles"
import { _Blockquote } from "./blockquote.styles"
import styled from "styled-components"

interface GenericItemProps {
    lead: string | string[]
    title?: string
    link?: {
        href: string
        title: string
    }
    image: string
    titleClassName?: string
    cardImageClassName?: string
    leadAlign?: string
    itemMaxWidth?: string
}

const _cardImage = styled.img`
    max-height: 100%;
    max-width: 150px;

    &.mainPage {
        max-width: 250px;
    }

    &.smallImage {
        max-width: 106px;
        display: block;
        margin-left: 0;
    }
`

const _cardWrapper = styled.div`
    margin: 0.5em;
`
const _cardTextWrapper = styled.div`
    text-align: left;
`

const _cardTitle = styled(_h3)`
    margin-bottom: 0.5em;
    text-align: left;
    font-weight: 600;
    font-size: 18px;
    text-transform: uppercase;
    margin-top: 25px;

    @media only screen and (min-width: 992px) {
        font-size: 18px;
    }

    &.mainPage {
        text-align: center;
        font-size: 40px;
    }
`

const _cardLink = styled.a`
    font-family: Nunito;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: 0.1px;
    color: var(--warm-blue);
`

export const GenericItem: React.FC<GenericItemProps> = ({
    itemMaxWidth,
    title,
    titleClassName,
    cardImageClassName,
    lead,
    link,
    image,
    leadAlign
}) => {
    let leadSection

    if (Array.isArray(lead)) {
        leadSection = lead.map((paragraph, index) => {
            return <p key={index}>{paragraph}</p>
        })
    } else {
        leadSection = lead
    }

    return (
        <_cardWrapper>
            <_cardImage className={cardImageClassName} src={image} />
            <_cardTextWrapper style={{ maxWidth: itemMaxWidth }}>
                {title && <_cardTitle className={titleClassName}>{title}</_cardTitle>}
                <_Blockquote {...(leadAlign && { align: leadAlign })} fontSize={16} lineHeight={1.5}>
                    {leadSection}
                </_Blockquote>

                {link ? <_cardLink href={link.href}>{link.title} ></_cardLink> : null}
            </_cardTextWrapper>
        </_cardWrapper>
    )
}
