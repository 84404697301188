import * as React from "react"
import { _h3, _h2 } from "./headline.styles"
import { _Blockquote } from "./blockquote.styles"
import styled from "styled-components"
import { _cardDescription } from "./cards/card.styles"

interface ShadowedBoxProps {
    title: string
    image: string
    description: string | string[]
    itemMaxWidth?: string
}

const _cardImage = styled.img`
    max-width: 75px;
    max-height: 65px;

    @media only screen and (min-width: 991px) {
        max-width: 75px;
        max-height: 75px;
    }
`

const _cardWrapper = styled.div`
    margin: 0;
    border-radius: 22px;
    box-shadow: 0 2px 7px -1px rgba(135, 134, 234, 20%);
    background-color: rgba(255, 255, 255, 0.66);
    display: flex;
    flex-direction: column;
    gap: 23px;
    padding: 15px 23px;
    position: relative;
    overflow: hidden;

    &:before {
        position: absolute;
        top: 0;
        left: 58px;
        content: "";
        filter: blur(28px);
        background-color: rgba(67, 64, 222, 0.64);
        width: 158px;
        height: 123px;
        opacity: 0.65;
        z-index: -1;
    }

    &:after {
        position: absolute;
        z-index: -1;
        top: 44px;
        left: 0;
        content: "";
        width: 210px;
        height: 180px;
        opacity: 0.64;
        filter: blur(28px);
        background-color: rgba(255, 129, 85, 0.64);
    }

    @media only screen and (min-width: 768px) {
        &:after {
            display: none;
        }
    }

    &:nth-child(2n) {
        &:before {
            background-color: rgba(255, 129, 85, 0.64);
        }

        &:after {
            display: none;
        }
    }

    @media only screen and (min-width: 768px) {
        flex-direction: row;
    }
`
const _cardTextWrapper = styled.div`
    text-align: left;
`

const _cardImageWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;

    @media only screen and (min-width: 991px) {
        justify-content: center;
        flex: 0 0 75px;
    }
`

const _cardTitle = styled(_h3)`
    margin-bottom: 0.5em;
    text-align: left;
    font-weight: 600;
    font-size: 16px;
    text-transform: uppercase;
    margin-top: 0;

    @media only screen and (min-width: 992px) {
        font-size: 16px;
    }
`

export const _shadowedBoxContainer = styled.div`
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 21px 22px;
    position: relative;

    @media only screen and (min-width: 768px) {
        padding-bottom: 2em;
        padding-top: 2em;
    }

    @media only screen and (min-width: 1400px) {
        width: 1400px;
        margin-left: calc((100% - 1400px) / 2);
    }
`

export const ShadowedBox: React.FC<ShadowedBoxProps> = ({ itemMaxWidth, title, image, description }) => {
    return (
        <_cardWrapper style={{ maxWidth: itemMaxWidth }}>
            <_cardImageWrapper>
                <_cardImage src={image} />
            </_cardImageWrapper>
            <_cardTextWrapper>
                {title && <_cardTitle>{title}</_cardTitle>}
                <_cardDescription>{description}</_cardDescription>
            </_cardTextWrapper>
        </_cardWrapper>
    )
}
