import * as React from "react"
import { _h2, _h3, _description } from "../headline.styles"
import { _Blockquote } from "../blockquote.styles"
import { _cardWrapper, _cardImage, _cardContentWrapper, _cardDescription, _cardTitle, _cardLink } from "./card.styles"
import styled from "styled-components"

interface ImageCardProps {
    className?: string
    imageUrl: string
    onClick?: (e: React.SyntheticEvent) => void
}

const _image = styled.img`
    max-width: 100%;
`

export const ImageCard: React.FC<ImageCardProps> = ({ imageUrl, onClick, className }) => {
    const classNames = `${onClick ? "clickableCardWrapper" : ""} ${className ? className : "noBoxShadow"}`

    return (
        <_cardWrapper onClick={onClick} margin={"0"} className={classNames} marginBottom={"1"}>
            <_cardContentWrapper padding={"0"}>
                <_image src={imageUrl} />
            </_cardContentWrapper>
        </_cardWrapper>
    )
}
