import styled from "styled-components"
import Logo from "../../images/logo/radicle-logo.png"
import Logo2x from "../../images/logo/radicle-logo@2x.png"
import Logo3x from "../../images/logo/radicle-logo@3x.png"
import { DESKTOP_SCREEN_WIDTH } from "../../utils/responsiveHelper"

export const _Nav = styled.nav`
    display: flex;
    align-items: center;
    top: 0;
    width: 100%;
    z-index: 2;
    flex: 0 0 100%;
    position: fixed;
    background-color: white;
    border-bottom: solid 1px #ebebeb;
    left: 0;
    right: 0;
`

export const _NavImageWrapper = styled.div`
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: -1;
    overflow: hidden;
`

export const _NavMenuItem = styled.li`
    display: inline-block;
    font-weight: 800;
    margin: 0;
    padding-right: 2.8125em;

    @media only screen and (min-width: ${DESKTOP_SCREEN_WIDTH}px) {
        &:last-child {
            padding: 0;
        }
    }
`

export const _NavMenu = styled.ul`
    list-style: none;
    margin: 0;
`

export const _NavWrapper = styled.div`
    width: 100%;
    padding: 1em 3.125em;
`

export const _NavLogo = styled.img.attrs({ src: Logo, srcSet: `${Logo2x} 2x, ${Logo3x} 3x`, alt: "Radicle logo" })`
    margin: 0;
    width: 6em;
    @media screen and (max-width: 100px) {
        height: 2.1875em;
    }
`

export const _NavLogoMobile = styled.img.attrs({
    src: Logo,
    srcSet: `${Logo2x} 2x, ${Logo3x} 3x`,
    alt: "Radicle logo"
})`
    height: 20px;
    margin: 0 0 0 1.5625em;
    align-self: center;
`

export const _mobileNavWrapper = styled.div`
    padding: 1.5em;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
`

export const _SideNavMenu = styled(_NavMenu)`
    margin-top: 2em;
    display: block !important;
    outline: none;

    li {
        display: block;
        padding: 0.5em 0em;
        outline: none;
    }
`

export const _SecondMenu = styled(_SideNavMenu)`
    margin: 1em 0em;
`

export const styles = {
    bmBurgerButton: {
        width: "25px",
        height: "18px",
        position: "relative"
    },
    bmBurgerBars: {
        background: "#373a47",
        height: "15%",
        borderRadius: "2px"
    },
    bmBurgerBarsHover: {
        background: "#a90000"
    },
    bmCrossButton: {
        height: "24px",
        width: "24px",
        right: "15px",
        top: "25px"
    },
    bmCross: {
        background: "#bdc3c7"
    },
    bmMenuWrap: {
        position: "fixed",
        height: "100%",
        width: "100%",
        left: "0",
        right: "0",
        top: "0",
        bottom: "0"
    },
    bmMenu: {
        background: "var(--white)",
        padding: "2.5em 1.5em 0",
        fontSize: "1.15em",
        paddingTop: "1em"
    },
    bmMorphShape: {
        fill: "#373a47"
    },
    bmItemList: {
        color: "#b8b7ad",
        padding: "0.8em"
    },
    bmItem: {
        display: "inline-block"
    },
    bmOverlay: {
        background: "rgba(0, 0, 0, 0.3)"
    }
}
