import fetch from "isomorphic-unfetch"

export const postRequest = (url: string, body: object) => {
    return fetch(url, {
        method: "post",
        body: JSON.stringify(body),
        headers: new Headers({
            "Content-Type": "application/json"
        })
    })
}
