import { graphql, useStaticQuery } from "gatsby"

export interface SiteMetadata {
    title: string
}

interface Data {
    site: {
        siteMetadata: SiteMetadata
    }
}

const siteMetadataQuery = graphql`
    query {
        site {
            siteMetadata {
                title
            }
        }
    }
`

/** Custom hook that returns the site metadata. */
export const useSiteMetadata = () => {
    const { site }: Data = useStaticQuery(siteMetadataQuery)

    const defaultSiteMeta = {
        title: "Radicle"
    }

    return site ? site.siteMetadata : defaultSiteMeta
}
