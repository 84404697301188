import styled from "styled-components"

interface BlockquoteProps {
    fontSize?: number
    fontSizeSm?: number
    lineHeight?: number
    opacity?: number
    color?: string
    align?: string
    alignSm?: string
    marginTop?: number | string
    marginBottom?: number | string
    maxWidth?: string
    marginLeft?: string
    marginRight?: string
}

export const _Blockquote = styled.blockquote<BlockquoteProps>`
    color: ${props => (props.color ? props.color : "var(--slate-grey)")};
    opacity: ${props => (props.opacity ? props.opacity : "1")};
    font-family: Nunito;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    text-align: ${props => (props.alignSm ? props.alignSm : "left")};
    margin-top: ${props => (props.marginTop ? props.marginTop : "1.5")}em;
    margin-bottom: ${props => (props.marginBottom ? props.marginBottom : "1.5")}em;
    letter-spacing: 0.1px;
    line-height: 1.56;
    font-size: ${props => (props.fontSizeSm ? props.fontSizeSm : "18")}px;
    position: relative;

    @media only screen and (min-width: 992px) {
        text-align: ${props => (props.align ? props.align : "left")};
        font-size: ${props => (props.fontSize ? props.fontSize : "18")}px;
        line-height: ${props => (props.lineHeight ? props.lineHeight : "1.56")};
        letter-spacing: normal;
        max-width: ${props => (props.maxWidth ? props.maxWidth : "unset")};
        margin-left: ${props => (props.marginLeft ? props.marginLeft : "unset")};
        margin-right: ${props => (props.marginRight ? props.marginRight : "unset")};
    }

    p,
    strong {
        color: inherit;
    }
`
