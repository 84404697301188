import * as React from "react"
import { Link, StaticQuery, graphql } from "gatsby"
import { Row, Grid, Col } from "react-flexbox-grid"
import { _Blockquote } from "../blockquote.styles"
import { _ImageLink } from "../links.styles"
import { _customAlignCol } from "../../shared"

import { prismicGetText, extractPrismicNode } from "../../../utils/prismicHelper"
import {
    _FooterWrapper,
    _FooterContainer,
    _FooterLogo,
    _FooterMenu,
    _FooterMenuItem,
    _Address,
    _SocialMenuItem,
    _SocialIcon,
    _CopyrightMenu,
    _CopyrightMenuItem,
    _CopyrightWrapper,
    _CopyrightContainer
} from "./footer.styles"

import { FormContainer } from "./NewsletterForm/Form"
export const footerQuery = graphql`
    {
        prismic {
            ...footerFragment
        }
    }
`

export const Footer: React.FC = () => {
    return <StaticQuery query={footerQuery} render={data => <FooterComponent data={data} />} />
}

interface PrismicTextProp {
    text: string
}

interface FooterComponent {
    data: {
        prismic: {
            allPage_footers: {
                edges: any
            }
        }
    }
}

interface PrismicSocialMediaLinkProps {
    social_media_link: any
    social_media_name: any
    social_media_icon: any
}

const FooterComponent: React.FC<FooterComponent> = ({ data }) => {
    // Required check for no data being returned
    const doc = extractPrismicNode(data, "allPage_footers")
    if (!doc) return null

    return (
        <_FooterWrapper>
            <Grid>
                <_FooterContainer>
                    <Row>
                        <Col lg={3} xs={12}>
                            <Row>
                                <Col xs={12}>
                                    <_ImageLink to="/">
                                        <_FooterLogo src={doc.footer_logo.url} />
                                    </_ImageLink>
                                </Col>

                                <Col xs={12}>
                                    <_Blockquote fontSize={14} lineHeight={1.43} opacity={0.4}>
                                        {prismicGetText(doc.footer_description)}
                                    </_Blockquote>
                                </Col>

                                <Col xs={12}>
                                    <FormContainer emailPlaceholder={doc.footer_newsletter} />
                                </Col>
                            </Row>
                        </Col>

                        <Col lgOffset={2} lg={7} xs={12}>
                            <Row middle="xs">
                                <Col lg={4} xs={12}>
                                    <_FooterMenu>
                                        <_FooterMenuItem>
                                            <Link to="/company" title="Work with us">
                                                {prismicGetText(doc.cta_label_company)}
                                            </Link>
                                        </_FooterMenuItem>

                                        <_FooterMenuItem>
                                            <a target="_blank" href={doc.join_our_team.url} title="Jois our team">
                                                {prismicGetText(doc.cta_label_joinourteam)}
                                            </a>
                                        </_FooterMenuItem>

                                        <_FooterMenuItem>
                                            <Link to="/insights" title="Insights">
                                                {prismicGetText(doc.cta_label_insights)}
                                            </Link>
                                        </_FooterMenuItem>
                                    </_FooterMenu>
                                </Col>

                                <Col lg={4} xs={12}>
                                    <_Address>
                                        {doc.footer_address.map((address: PrismicTextProp, index: number) => (
                                            <p key={index}>{address.text}</p>
                                        ))}

                                        <a target="_blank" href={doc.google_maps_link.url}>
                                            {prismicGetText(doc.cta_label_google_maps)}
                                        </a>
                                    </_Address>
                                </Col>

                                <Col lg={4} xs={12}>
                                    <_FooterMenu>
                                        {doc.body[0].fields.map(
                                            (socialField: PrismicSocialMediaLinkProps, index: number) => (
                                                <_SocialMenuItem key={index}>
                                                    <a
                                                        href={socialField.social_media_link.url}
                                                        target="_blank"
                                                        title={prismicGetText(socialField.social_media_name)}>
                                                        <_SocialIcon src={socialField.social_media_icon.url} />
                                                        {prismicGetText(socialField.social_media_name)}
                                                    </a>
                                                </_SocialMenuItem>
                                            )
                                        )}
                                    </_FooterMenu>
                                </Col>
                            </Row>
                        </Col>
                    </Row>

                    <Row>
                        <Col xs={12}>
                            <_CopyrightWrapper>
                                <_CopyrightContainer>
                                    <Row middle="lg">
                                        <Col lg={6} md={5} sm={12}>
                                            {prismicGetText(doc.footer_rights)}
                                        </Col>
                                        <_customAlignCol lg={6} md={7} sm={12} align="right">
                                            <_CopyrightMenu>
                                                <_CopyrightMenuItem>
                                                    <Link to="/privacy-policy" title="Privacy policy">
                                                        {prismicGetText(doc.cta_label_privacy)}
                                                    </Link>
                                                </_CopyrightMenuItem>

                                                <_CopyrightMenuItem>
                                                    <Link to="/terms" title="Terms of services">
                                                        {prismicGetText(doc.cta_label_terms)}
                                                    </Link>
                                                </_CopyrightMenuItem>
                                            </_CopyrightMenu>
                                        </_customAlignCol>
                                    </Row>
                                </_CopyrightContainer>
                            </_CopyrightWrapper>
                        </Col>
                    </Row>
                </_FooterContainer>
            </Grid>
        </_FooterWrapper>
    )
}
