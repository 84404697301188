import * as React from "react"

import { AppStateContext } from "../../../utils/PageWrapper"
import styled from "styled-components"
import { Col, Row } from "react-flexbox-grid"
import { _Blockquote } from "../../shared/blockquote.styles"
import { _h2 } from "../../shared/headline.styles"
import { _processLink } from "../../shared/links.styles"
import CheckIcon from "../../../images/v2/check-icon.svg"

const _list = styled.ul`
    list-style: none;
    margin: 0;
    padding: 0;
    margin-top: 0em;
`

const _item = styled.li`
    font-family: Nunito;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: 0.1px;
    color: var(--slate-grey);
    display: flex;
    margin-bottom: 0.7em;

    img {
        margin-right: 1em;
        width: 20px;
        height: 24px;
    }
`

const _itemContent = styled.div`
    p {
        margin: 0;
        width: 100%;
        display: block;
    }
`

const _svgImage = styled.img`
    z-index: -1;
`

interface ChecklistSectionProps {
    title: string | JSX.Element
    lead: string
    list: string[]
    image: string
    imagePosition?: string
    maxTitleWidth?: string
    twoColumns?: boolean
}

const contentSection = ({
    title,
    lead,
    list,
    maxTitleWidth,
    twoColumns
}: {
    title: string | JSX.Element
    lead: string
    list: string[]
    maxTitleWidth: string
    twoColumns: boolean
}): JSX.Element => {
    return (
        <>
            <_h2 maxWidth={maxTitleWidth} align="left">
                {title}
            </_h2>
            <_Blockquote fontSize={16}>{lead}</_Blockquote>

            {twoColumns ? (
                <Row>
                    <Col md={6} sm={12}>
                        <_list>
                            {list.map((element, index) => {
                                if (index >= list.length / 2) return null
                                return (
                                    <_item key={index}>
                                        <img src={CheckIcon} />
                                        <_itemContent>
                                            {element}
                                        </_itemContent>
                                    </_item>
                                )
                            })}
                        </_list>
                    </Col>

                    <Col md={6} sm={12}>
                        <_list>
                            {list.map((element, index) => {
                                if (index < list.length / 2) return null
                                return (
                                    <_item key={index}>
                                        <img src={CheckIcon} />
                                        <_itemContent>
                                            {element}
                                        </_itemContent>
                                    </_item>
                                )
                            })}
                        </_list>
                    </Col>
                </Row>
            ) : (
                <Row>
                    <Col md={12} sm={12}>
                        <_list>
                            {list.map((element, index) => (
                                <_item key={index}>
                                    <img src={CheckIcon} />
                                    <_itemContent>
                                        {element}
                                    </_itemContent>
                                </_item>
                            ))}
                        </_list>
                    </Col>
                </Row>
            )}
        </>
    )
}

const imageSection = (image: string): JSX.Element => {
    return <_svgImage src={image} />
}

export const ChecklistSection: React.FC<ChecklistSectionProps> = ({
    title,
    lead,
    list,
    image,
    imagePosition,
    maxTitleWidth = "auto",
    twoColumns = false
}) => {
    const appState = React.useContext(AppStateContext)

    const isRight = imagePosition === "right"
    const leftSide = isRight ? contentSection({ title, lead, list, maxTitleWidth, twoColumns }) : imageSection(image)
    const rightSide = isRight ? imageSection(image) : contentSection({ title, lead, list, maxTitleWidth, twoColumns })

    const desktopContent = (
        <Row middle="xs">
            <Col lg={5} md={8} sm={12}>
                {leftSide}
            </Col>

            <Col lgOffset={2} lg={5} md={8} sm={12}>
                {rightSide}
            </Col>
        </Row>
    )

    const mobileContent = (
        <Row middle="xs">
            <Col sm={12}>
                <_h2 fontSizeSm={36} align="left">
                    {title}
                </_h2>
                <img style={{ margin: "auto", width: "100%" }} src={image} />

                <_Blockquote fontSize={16}>{lead}</_Blockquote>

                <_list>
                    {list.map((element, index) => (
                        <_item key={index}>
                            <img src={CheckIcon} />
                            <_itemContent>
                                {element}
                            </_itemContent>
                        </_item>
                    ))}
                </_list>
            </Col>
        </Row>
    )

    return appState.isMobile ? mobileContent : desktopContent
}
