import React from "react"
import { Link } from "gatsby"
import { Row, Col } from "react-flexbox-grid"
import { _ImageLink, Dropdown, _NavLink, _ExternalNavLink, _SecondNavLink } from "./links.styles"
import { NavButton, NavBlockButton } from "./buttons"
import { useSiteMetadata } from "../../hooks"
import { getWindowDimensions } from "../../utils"
import { slide as Slide } from "react-burger-menu"
import { em } from "../../styles"
import { DESKTOP_SCREEN_WIDTH } from "../../utils/responsiveHelper"
import { AppStateContext } from "../../utils/PageWrapper"
import { NavigationLinkProps } from "../../links"

import {
    _Nav,
    _NavImageWrapper,
    _NavMenuItem,
    _NavMenu,
    _NavWrapper,
    _NavLogo,
    _NavLogoMobile,
    _mobileNavWrapper,
    _SideNavMenu,
    _SecondMenu,
    styles
} from "./navigation.styles"

type NavigationProps = {
    navLinks: NavigationLinkProps[]
    mobileNavLinks: NavigationLinkProps[]
    navbarImage?: JSX.Element
}

export const Navigation: React.FC<NavigationProps> = ({ navLinks = [], mobileNavLinks = [], navbarImage }) => {
    const [windowDimensions, setWindowDimensions] = React.useState<number>(getWindowDimensions())
    const appState = React.useContext(AppStateContext)
    const { title } = useSiteMetadata()

    const dropdownLinks = (links: any): JSX.Element => {
        return (
            <_SecondMenu>
                {links.map((dropdownLink: NavigationLinkProps) => (
                    <_NavMenuItem key={dropdownLink.href}>
                        <_NavLink activeClassName="active" to={dropdownLink.href} title={dropdownLink.title}>
                            {dropdownLink.content}
                        </_NavLink>
                    </_NavMenuItem>
                ))}
            </_SecondMenu>
        )
    }

    React.useEffect(() => {
        setWindowDimensions(getWindowDimensions())

        function handleResize() {
            setWindowDimensions(getWindowDimensions())
        }

        window.addEventListener("resize", handleResize)
        return () => window.removeEventListener("resize", handleResize)
    })

    if (!appState.isReady) return null

    return (
        <_Nav>
            {windowDimensions <= DESKTOP_SCREEN_WIDTH ? (
                <_mobileNavWrapper>
                    {appState.isReady && (
                        <Slide width={"100%"} styles={styles}>
                            <Link to="/">
                                <_NavLogoMobile />
                            </Link>

                            <_SideNavMenu>
                                {mobileNavLinks.map((v: NavigationLinkProps) => {
                                    if (!v) return null
                                    return (
                                        <_NavMenuItem key={v.href}>
                                            {v.breakBefore && <div style={{ marginTop: em(2) }} />}

                                            {v.link && (
                                                <_NavLink activeClassName="active" to={v.href} title={v.title}>
                                                    {v.content}
                                                </_NavLink>
                                            )}

                                            {v.externalLink && (
                                                <_ExternalNavLink target="_blank" href={v.href} title={v.title}>
                                                    {v.content}
                                                </_ExternalNavLink>
                                            )}

                                            {v.button && (
                                                <NavBlockButton to={v.href!} title={v.title}>
                                                    {v.content}
                                                </NavBlockButton>
                                            )}

                                            {v.extraLink && (
                                                <_SecondNavLink activeClassName="active" to={v.href} title={v.title}>
                                                    {v.content}
                                                </_SecondNavLink>
                                            )}

                                            {v.dropdown && (
                                                <>
                                                    <_NavLink activeClassName="active" to="#" title={v.title}>
                                                        {v.content}
                                                    </_NavLink>

                                                    {dropdownLinks(v.dropdownLinks)}
                                                </>
                                            )}
                                        </_NavMenuItem>
                                    )
                                })}
                            </_SideNavMenu>
                        </Slide>
                    )}

                    <Link to="/">
                        <_NavLogoMobile />
                    </Link>

                    <NavButton className={"rightAlign"} to={"/contact"} title={"contact"}>
                        Contact us
                    </NavButton>
                </_mobileNavWrapper>
            ) : (
                <_NavWrapper>
                    <_NavImageWrapper>{navbarImage}</_NavImageWrapper>

                    <Row middle="xs">
                        <Col xs={3} lg={2}>
                            <Row start="md">
                                <_ImageLink to="/">
                                    <_NavLogo title={`${title} index`} />
                                </_ImageLink>
                            </Row>
                        </Col>

                        <Col xs={9} lg={10}>
                            <Row end="md">
                                <_NavMenu>
                                    {navLinks.map((v: NavigationLinkProps) => {
                                        if (!v) return null
                                        return (
                                            <_NavMenuItem key={v.href}>
                                                {v.link && (
                                                    <_NavLink
                                                        className={v.customClassName}
                                                        activeClassName="active"
                                                        to={v.href}
                                                        title={v.title}>
                                                        {v.content}
                                                    </_NavLink>
                                                )}

                                                {v.externalLink && (
                                                    <_ExternalNavLink
                                                        className={v.customClassName}
                                                        target="_blank"
                                                        href={v.href}
                                                        title={v.title}>
                                                        {v.content}
                                                    </_ExternalNavLink>
                                                )}

                                                {v.button && (
                                                    <NavButton
                                                        className={v.customClassName}
                                                        to={v.href!}
                                                        title={v.title}>
                                                        {v.content}
                                                    </NavButton>
                                                )}

                                                {v.dropdown && <Dropdown title={v.content} links={v.dropdownLinks} />}
                                            </_NavMenuItem>
                                        )
                                    })}
                                </_NavMenu>
                            </Row>
                        </Col>
                    </Row>
                </_NavWrapper>
            )}
        </_Nav>
    )
}
