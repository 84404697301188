import * as React from "react"
import { _h2, _h3, _description } from "../headline.styles"
import { _Blockquote } from "../blockquote.styles"
import { Link } from "gatsby"
import {
    _cardWrapper,
    _cardImagePlaceholder,
    _cardContentWrapper,
    _cardDescription,
    _cardTitle,
    _cardLink
} from "./card.styles"
import styled from "styled-components"
import { AppStateContext } from "../../../utils/PageWrapper"
import { Row, Col } from "react-flexbox-grid"
import LinesEllipsis from "react-lines-ellipsis"
import { PostCardTag } from "../tags"

const _customCardDescription = styled(_cardDescription)`
    color: var(--slate-grey);
    display: flex;
`

const _mobileCardTitle = styled(_cardTitle)`
    flex: 1;
    margin: 0;
    font-size: 18px;
`

const _flexBox = styled.div`
    display: flex;
`

const _mobileCardImagePlaceholder = styled(_cardImagePlaceholder)`
    height: 70px;
    width: 70px;
    margin-left: 1em;
    margin-bottom: 1em;
`

const _mobileCustomCardDescription = styled(_customCardDescription)`
    font-size: 13px;
`

const _mobileCardWrapper = styled(_cardWrapper)`
    box-shadow: none;
    border-bottom: 1px solid #cccccc6b;
    border-radius: 0;
`

const _mobileCardContentWrapper = styled(_cardContentWrapper)`
    padding: 1em 0;
`

const _dateContainer = styled.div`
    font-size: 12px;
    line-height: normal;
    letter-spacing: 0.08px;
    color: #646c7a;

    margin-left: auto;
`

const _postCategory = styled.div`
    font-size: 14px;
    font-weight: bold;
    line-height: 0.71;
    letter-spacing: 0.09px;
    color: #4340de;
`

const _mobilePostCategory = styled(_postCategory)`
    margin-bottom: 10px;
`

const _flexRow = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-content: space-between;
    vertical-align: middle;
    align-items: center;
    width: 100%;
`

const _tagsContainer = styled.div`
    height: 16px;
`

interface BlogPostCardProps {
    title: string
    date: string
    link?: {
        to?: string
        prevPath?: string
    }
    image: string
    className?: string
    author?: string
    source?: string
    externalLink?: {
        href: string
    }
    isMobile?: boolean
    tags?: string[]
    category: string
}

interface BlogPostLinkProps {
    link?: {
        to?: string
        prevPath?: string
    }
    externalLink?: {
        href: string
    }
}

const BlogPostLink: React.FC<BlogPostLinkProps> = ({ externalLink, link, children }) => {
    let result = null

    if (externalLink && externalLink.href) {
        result = (
            <a target="_blank" rel="noreferrer" href={externalLink.href}>
                {children}
            </a>
        )
    }

    if (link && link.to) {
        result = (
            <Link to={link.to} state={{ prevPath: link.prevPath }}>
                {children}
            </Link>
        )
    }

    return result
}

export const BlogPostCard: React.FC<BlogPostCardProps> = ({
    title,
    date,
    author,
    link,
    image,
    source,
    externalLink,
    className,
    tags,
    category
}) => {
    const appState = React.useContext(AppStateContext)

    const desktopContent = (
        <BlogPostLink externalLink={externalLink} link={link}>
            <_cardWrapper margin={"0"} className={className ? className : ""}>
                <_cardImagePlaceholder image={image} />
                <_cardContentWrapper align="left" style={{ padding: "1.5em 10px" }}>
                    <_customCardDescription>
                        <_flexRow>
                            <_postCategory>{category}</_postCategory>
                            <_dateContainer>{date}</_dateContainer>
                        </_flexRow>
                    </_customCardDescription>

                    <_customCardDescription style={{ marginTop: "5px" }}>
                        <_flexRow>
                            <_tagsContainer>
                                {tags?.map(tag => (
                                    <PostCardTag key={tag} tag={`#${tag}`} />
                                ))}
                            </_tagsContainer>
                        </_flexRow>
                    </_customCardDescription>
                    <_cardTitle>
                        <LinesEllipsis
                            style={{ whiteSpace: "pre-wrap" }}
                            text={title}
                            maxLine="3"
                            ellipsis="..."
                            basedOn="words"
                        />
                    </_cardTitle>

                    {author && <_customCardDescription>By {author}</_customCardDescription>}
                    {source && <_customCardDescription>Publication: {source}</_customCardDescription>}
                </_cardContentWrapper>
            </_cardWrapper>
        </BlogPostLink>
    )

    const mobileContent = (
        <BlogPostLink externalLink={externalLink} link={link}>
            <_mobileCardWrapper margin={"0"} className={className ? className : ""}>
                <_mobileCardContentWrapper align="left">
                    <_mobileCustomCardDescription>
                        <_mobilePostCategory>{category}</_mobilePostCategory>
                    </_mobileCustomCardDescription>
                    <Row>
                        <Col sm={12}>
                            <_flexBox>
                                <_mobileCardTitle>
                                    <LinesEllipsis
                                        style={{ whiteSpace: "pre-wrap" }}
                                        text={title}
                                        maxLine="3"
                                        ellipsis="..."
                                        basedOn="words"
                                    />
                                </_mobileCardTitle>
                                <_mobileCardImagePlaceholder image={image} />
                            </_flexBox>
                        </Col>
                    </Row>

                    <_mobileCustomCardDescription>{author}</_mobileCustomCardDescription>
                    <_mobileCustomCardDescription>{date}</_mobileCustomCardDescription>

                    {tags && tags.length != 0 && (
                        <_mobileCustomCardDescription>
                            <_flexRow>
                                <_tagsContainer>
                                    {tags?.map(tag => (
                                        <PostCardTag key={tag} tag={`#${tag}`} />
                                    ))}
                                </_tagsContainer>
                            </_flexRow>
                        </_mobileCustomCardDescription>
                    )}
                </_mobileCardContentWrapper>
            </_mobileCardWrapper>
        </BlogPostLink>
    )

    if (!appState.isReady) return null
    return appState.isMobile ? mobileContent : desktopContent
}
