import * as React from "react"

import { Row, Col } from "react-flexbox-grid"
import styled from "styled-components"
import scrollTo from "gatsby-plugin-smoothscroll"

import { AppStateContext } from "../../../utils/PageWrapper"
import { _h1, _description } from "../../shared/headline.styles"
import { _Blockquote } from "../../shared/blockquote.styles"
import { _linkButton, _linkBlockButton, _externalLinkButton } from "../../shared/buttons/"
import { ImageWithBlur } from "../../shared/"
import Hexagon2 from "../../../images/v2/hexagon-2.svg"

interface HeroProps {
    title: JSX.Element | string
    lead: string | JSX.Element
    buttonLabel?: string
    description?: string
    image?: string
    center?: boolean
    imageClassName?: string
    headerFontSize?: number
    anchorButtonTo?: string
    leftSideLgWidth?: number
    rightSideOffset?: number
    customElement?: JSX.Element
    heroImage?: {
        height: string
        isBlured?: boolean
        topOffset?: string
        maxImgHeight?: string
    }
    showImageOnMobile?: boolean
    showHexagon?: boolean
    headingMarginTop?: number
}

const _svgImage = styled.img`
    position: absolute;
    top: -140px;
    left: 83%;
    z-index: -1;
`

export const Hero: React.FC<HeroProps> = ({
    description,
    customElement,
    title,
    lead,
    buttonLabel,
    image,
    center,
    imageClassName,
    children,
    headerFontSize,
    anchorButtonTo,
    leftSideLgWidth = 5,
    rightSideOffset = 1,
    heroImage,
    showHexagon = false,
    showImageOnMobile = true,
    headingMarginTop = 0.2
}) => {
    const appState = React.useContext(AppStateContext)
    const desktopContent = (
        <>
            {showHexagon && <_svgImage src={Hexagon2} />}
            <Row {...(center ? { center: "md" } : {})}>
                <Col lg={leftSideLgWidth} md={12} sm={12}>
                    <_description fontSize={14} uppercase={true} align={center ? "center" : ""}>
                        {description}
                    </_description>
                    <_h1
                        {...(headerFontSize ? { fontSize: headerFontSize } : {})}
                        align={center ? "center" : ""}
                        style={{ marginTop: headingMarginTop + "em" }}>
                        {title}
                    </_h1>

                    <_Blockquote align={center ? "center" : ""}>{lead}</_Blockquote>

                    {anchorButtonTo ? (
                        <_externalLinkButton
                            href={anchorButtonTo}
                            onClick={event => {
                                event.preventDefault()
                                scrollTo(anchorButtonTo)
                            }}>
                            {buttonLabel}
                        </_externalLinkButton>
                    ) : buttonLabel ? (
                        <_linkButton to="/contact">{buttonLabel}</_linkButton>
                    ) : null}
                </Col>

                {!center ? (
                    <Col lg={12 - leftSideLgWidth - rightSideOffset} lgOffset={rightSideOffset} md={12} sm={12}>
                        <Row end="lg" center="md">
                            {image ? <ImageWithBlur image={image} className={imageClassName} {...heroImage} /> : null}
                            { customElement }
                        </Row>
                    </Col>
                ) : null}
            </Row>

            <Row {...(center ? { center: "md" } : {})}>{children}</Row>
        </>
    )

    const mobileContent = (
        <>
            <Row>
                <Col lg={5} md={12} sm={12}>
                    <_description uppercase={true}>{description}</_description>
                    <_h1 marginTop={0.2} marginBottom={1}>
                        {title}
                    </_h1>

                    {image && showImageOnMobile && (
                        <ImageWithBlur image={image} className={imageClassName} {...heroImage} />
                    )}

                    <_Blockquote>{lead}</_Blockquote>

                    {anchorButtonTo ? (
                        <_externalLinkButton
                            href={anchorButtonTo}
                            onClick={event => {
                                event.preventDefault()
                                scrollTo(anchorButtonTo)
                            }}>
                            {buttonLabel}
                        </_externalLinkButton>
                    ) : buttonLabel ? (
                        <_linkButton to="/contact">{buttonLabel}</_linkButton>
                    ) : null}
                </Col>
            </Row>

            <Row>{children}</Row>
        </>
    )

    return appState.isMobile ? mobileContent : desktopContent
}
