export interface NavigationLinkProps {
    content: string
    href?: string
    title?: string
    link?: boolean
    externalLink?: boolean
    breakBefore?: boolean
    extraLink?: boolean
    button?: boolean
    dropdown?: boolean
    dropdownLinks?: DropdownLinkProps[]
    customClassName?: string
}

export interface DropdownLinkProps {
    content: string
    href: string
}

const resolveEventLink = (eventLink: { href?: string; isVisible?: boolean }) => {
    if (!eventLink.isVisible) return undefined

    return {
        content: "Events",
        href: eventLink.href,
        externalLink: true
    }
}

export const navigationLinks = (
    pageTitle: string,
    isMobile: boolean,
    eventLink: { href?: string; isVisible?: boolean }
) => {
    const sharedNavLinks: NavigationLinkProps[] = [
        {
            content: "Home",
            href: "/",
            title: `${pageTitle} index`,
            link: true
        },
        {
            content: "Products",
            href: "/products",
            title: `${pageTitle} index`,
            link: true
        },
        /*{
            content: "Data",
            href: "/data",
            title: `${pageTitle} index`,
            link: true
        },*/
        {
            content: "Insights",
            href: "/insights",
            title: `${pageTitle} index`,
            link: true
        },
        resolveEventLink(eventLink) as NavigationLinkProps,
        // {
        //     content: "Network",
        //     href: "/network",
        //     title: `${pageTitle} index`,
        //     link: true
        // },
        {
            content: "Company",
            href: "/company",
            title: `${pageTitle} index`,
            link: true
        },
        {
            content: "Login",
            href: "https://radicleinsights.com/app/user/login",
            customClassName: "loginButton",
            title: `${pageTitle} index`,
            externalLink: true
        }
    ]

    const mobileOnlyLinks = [
        {
            content: "Privacy policy",
            href: "/privacy-policy",
            title: `${pageTitle} index`,
            extraLink: true,
            breakBefore: true
        },
        {
            content: "Terms and conditions",
            href: "/terms",
            title: `${pageTitle} index`,
            extraLink: true
        },
        {
            content: "Talk to us",
            href: "/contact",
            title: `${pageTitle} index`,
            button: true
        }
    ]

    const desktopOnlyLinks = [
        {
            content: "Contact us",
            href: "/contact",
            title: `${pageTitle} index`,
            button: true
        }
    ]

    return sharedNavLinks.concat(isMobile ? mobileOnlyLinks : desktopOnlyLinks)
}
