import * as React from "react"
import styled from "styled-components"
import { Col } from "react-flexbox-grid"
import { SliderSettings } from "../shared/"
import { _customMarginRow } from "../shared/row.styles"
import Slider from "react-slick"
import { AppStateContext } from "../../utils/PageWrapper"

export const _clientImage = styled.img`
    margin-top: 0.5em;
    margin-bottom: 0.5em;
    max-height: 60px;
    max-width: 95px;
    transition: 0.3s;

    @media only screen and (min-width: 992px) {
        max-height: 100px;
        max-width: 160px;
        margin-top: 1em;
        margin-bottom: 1em;
    }
`

const _clientImageLink = styled.a`
    text-decoration: none;

    &:hover {
        border: none;

        img {
            transform: scale(1.1);
        }
    }
`

interface ClientImageListProps {
    clientLogos: ClientImageItem[]
}

interface ClientImageItem {
    client_logo: {
        alt?: string
        url: string
    }
    client_website_link?: {
        url: string
    }
}

const desktopSingleImageItem = (client_logo: ClientImageItem, index: number) =>
    client_logo.client_website_link ? (
        <_clientImageLink href={client_logo.client_website_link.url} target="_blank" key={index}>
            <_clientImage src={client_logo.client_logo.url} alt={client_logo.client_logo.alt} />
        </_clientImageLink>
    ) : (
        <_clientImage src={client_logo.client_logo.url} alt={client_logo.client_logo.alt} key={index} />
    )

export const ClientImageList: React.FC<ClientImageListProps> = ({ clientLogos }) => {
    const appState = React.useContext(AppStateContext)
    if (!appState.isReady) return null

    const slidesToShow = appState.isMobile ? 3 : 5
    const slidesToScroll = slidesToShow
    const showDots = appState.isMobile ? false : true

    return (
        <Col md={12} style={{ marginTop: "2em", marginBottom: appState.isMobile ? "1em" : "4em" }}>
            <Slider {...SliderSettings({ slidesToShow: slidesToShow, slidesToScroll: slidesToScroll, dots: showDots })}>
                {clientLogos.map((client_logo, index: number) => {
                    return desktopSingleImageItem(client_logo, index)
                })}
            </Slider>
        </Col>
    )
}
