import styled from "styled-components"
import { DESKTOP_SCREEN_WIDTH, TABLET_SCREEN_WIDTH } from "../../../utils/responsiveHelper"

export const _FooterWrapper = styled.footer`
    border-top: 1px solid #ccc;
`

export const _FooterContainer = styled.footer`
    padding-top: 3em;
    padding-bottom: 1em;
`

export const _FooterLogo = styled.img.attrs({ alt: "Radicle logo" })`
    margin: 0;
    width: 6em;
    @media screen and (max-width: 100px) {
        height: 2.1875em;
    }
`

export const _FooterMenu = styled.ul`
    list-style: none;
    margin: 0;

    @media only screen and (max-width: ${DESKTOP_SCREEN_WIDTH}px) {
        padding: 0;
        margin-top: 3em;
        margin-bottom: 3em;

        li {
            margin-left: 0;
        }
    }
`

export const _FooterMenuItem = styled.li`
    display: block;
    font-weight: 800;
    margin: 0;
    height: 24px;
    margin: 1em;

    a {
        font-family: Nunito;
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: 0.1px;
        color: var(--dark);
    }
`

export const _Address = styled.div`
    font-family: Nunito;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.71;
    letter-spacing: normal;
    text-align: left;
    color: var(--slate-grey);

    @media only screen and (min-width: ${DESKTOP_SCREEN_WIDTH}px) {
        text-align: right;
    }

    p {
        margin-bottom: 0;
        margin-top: 0;
    }
`

export const _SocialMenuItem = styled.li`
    display: block;
    margin: 0;
    height: 24px;
    margin: 0.7em;
    text-align: left;

    a {
        font-family: HelveticaNeue;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 2.29;
        letter-spacing: normal;
        color: var(--black);
    }
`

export const _SocialIcon = styled.img`
    width: 16px;
    height: 16px;
    object-fit: contain;
    margin-right: 10px;
    margin-bottom: -2px;
`

export const _CopyrightMenu = styled.ul`
    list-style: none;
    margin: 0;

    @media only screen and (max-width: ${TABLET_SCREEN_WIDTH - 1}px) {
        padding: 0;
        text-align: left;

        li {
            display: block;
            margin-left: 0;
            margin: 1em 0;
        }
    }
`

export const _CopyrightMenuItem = styled.li`
    display: inline-block;
    margin-left: 2em;

    a {
        font-family: Nunito;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.43;
        letter-spacing: normal;
        color: var(--slate-grey);
    }
`

export const _CopyrightWrapper = styled.div`
    margin-top: 4em;
    width: 100%;

    @media only screen and (max-width: ${TABLET_SCREEN_WIDTH}px) {
        margin-top: 1em;
    }
`
export const _CopyrightContainer = styled.div`
    opacity: 0.4;
    font-family: Nunito;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: normal;
    color: var(--slate-grey);
`
