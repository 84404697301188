import styled from "styled-components"

export const _EmailInput = styled.input.attrs(props => ({
    type: "text",
    size: props.size || "0.7em"
}))`
    border: none;
    font-size: 1em;
    border-radius: 4px;
    height: 48px;
    width: 100%;
    background-blend-mode: multiply;
    background-image: linear-gradient(to bottom, rgba(9, 19, 33, 0.06), rgba(9, 19, 33, 0.06));
    margin: 0;
    margin-top: 0.5em;
    padding: ${props => props.size};

    font-family: Nunito;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: 0.1px;
    color: var(--slate-grey);
`

export const _submitImageButton = styled.button`
    background: none;
    top: 0;
    right: 0;
    position: relative;
    display: inline-block;
    background-image: linear-gradient(to bottom, rgba(9, 19, 33, 0.06), rgba(9, 19, 33, 0.06));
    border: none;
    cursor: pointer;
    margin: 0;
`

export const _formContainer = styled.div`
    position: relative;
    width: 100%;
    display: flex;

    input {
        margin-top: 0;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }
`
