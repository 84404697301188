import * as React from "react"
import { _Blockquote } from "../shared/blockquote.styles"
import Slider from "react-slick"
import { TestimonialItem } from "./TestimonialItem"
import { prismicGetText } from "../../utils/prismicHelper"
import { RichText } from "prismic-reactjs"
import { SliderSettings } from "../shared/"
import { AppStateContext } from "../../utils/PageWrapper"

import NextSlideButton from "../../images/v2/arrow-down-blue.svg"
import styled from "styled-components"

const _nextSlideButton = styled.button`
    background-color: transparent;
    border: none;
    position: absolute;
    right: 0;
    bottom: 30px;

    img {
        transform: rotate(-90deg);
    }
`

interface TestimonialProps {
    type: string
    text: string
}

interface TestimonialListProps {
    testimonialFields: TestimonialItemProps[]
    isMobile: boolean
}

export interface TestimonialItemProps {
    testimonial: [TestimonialProps]
    name: any
    avatar: any
    position: any
}

export const Testimonials: React.FC<TestimonialListProps> = ({ testimonialFields, isMobile }) => {
    const slider = React.useRef<Slider>(null)
    const appState = React.useContext(AppStateContext)
    const slidesToShow = 1

    const scrollToNextSlide = () => {
        if (slider && slider.current) {
            slider.current.slickNext()
        }
    }

    const filtered = testimonialFields.filter(v => {
        return Boolean(v.testimonial)
    })

    if (!appState.isReady) return null

    return (
        <>
            <Slider
                ref={slider}
                {...SliderSettings({
                    slidesToShow,
                    extraDotsClass: appState.isMobile ? "mobileBlue" : "horizontal",
                    arrows: false
                })}>
                {filtered.map((field, index: number) => (
                    <TestimonialItem
                        key={index}
                        body={<RichText render={field.testimonial} />}
                        name={prismicGetText(field.name)}
                        title={prismicGetText(field.position)}
                        image={field.avatar.url}
                        isMobile={isMobile}
                    />
                ))}
            </Slider>

            {!appState.isMobile && filtered.length > 1 && (
                <_nextSlideButton onClick={scrollToNextSlide}>
                    <img src={NextSlideButton} />
                </_nextSlideButton>
            )}
        </>
    )
}
