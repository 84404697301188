import styled from "styled-components"
import { Row } from "react-flexbox-grid"

interface RowProps {
    marginTop?: number | string
    marginBottom?: number | string
}

export const _customMarginRow = styled(Row)<RowProps>`
    margin-top: ${props => (props.marginTop ? props.marginTop : "1.5")}em;
    margin-bottom: ${props => (props.marginBottom ? props.marginBottom : "1.5")}em;
`
