export const MOBILE_SCREEN_WIDTH = 576
export const TABLET_SCREEN_WIDTH = 768
export const DESKTOP_SCREEN_WIDTH = 992
export const LARGE_DESKTOP_SCREEN_WIDTH = 1200

export const getWindowDimensions = (): number => {
    return typeof window !== `undefined` ? window.innerWidth : DESKTOP_SCREEN_WIDTH
}

export const checkIfMobile = (windowDimensions: number): boolean => windowDimensions <= TABLET_SCREEN_WIDTH

export const truncate = (text: string, n: number): string => {
    return text.length > n ? text.substr(0, n - 1) + "..." : text
}
