import * as React from "react"
import { Helmet } from "react-helmet"

interface HeadProps {
    title: string
    description?: string
    keywords?: string
    og_tags?: {
        image_url?: string
        title?: string
        description?: string
        type?: string
    }
}

export const HeadComponent: React.FC<HeadProps> = ({ title, description, keywords, og_tags }) => {
    return (
        <Helmet>
            <meta charSet="utf-8" />
            <title>{title}</title>
            <meta name="description" content={description} />
            <meta name="keywords" content={keywords} />
            {og_tags?.image_url && <meta property="og:image" content={og_tags.image_url} />}
            {og_tags?.title && <meta property="og:title" content={og_tags.title} />}
            {og_tags?.description && <meta property="og:description" content={og_tags.description} />}
            {og_tags?.type && <meta property="og:type" content={og_tags.type} />}
        </Helmet>
    )
}
