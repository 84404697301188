import * as React from "react"

import { _processExternalLink } from "./links.styles"
import Slider from "react-slick"
import { SliderSettings } from "./"
import { ImageCard, VideoCard } from "./index"
import { AppStateContext } from "../../utils/PageWrapper"

interface MediaCarouselProps {
    items: ItemProps[]
}

interface ItemProps {
    type: string
    url: string
}

const renderItem = (item: ItemProps, index: string | number) => {
    switch (item.type) {
        case "image":
            return <ImageCard key={index} imageUrl={item.url} />
            break
        case "video":
            return <VideoCard key={index} videoUrl={item.url} />
            break
        default:
            return null
            break
    }
}

export const MediaCarousel: React.FC<MediaCarouselProps> = ({ items }) => {
    const appState = React.useContext(AppStateContext)
    if (!appState.isReady) return null

    return (
        <Slider {...SliderSettings({ slidesToShow: 1 })}>{items.map((item, index) => renderItem(item, index))}</Slider>
    )
}
