import * as React from "react"

import styled from "styled-components"
import { Grid } from "react-flexbox-grid"
import { AppStateContext } from "../../utils/PageWrapper"
import hexagonMini1 from "../../images/v2/hexagon-mini-1.svg"

interface FullWidthSectionProps {
    displayBottomImage?: boolean
    displayTopImage?: boolean
    children: JSX.Element
}

const _fullWidthSection = styled.div`
    width: 100vw;
    margin-left: calc((100% - 100vw) / 2);
    position: relative;
    overflow: hidden;

    padding-bottom: 2em;
    padding-top: 2em;

    @media only screen and (min-width: 992px) {
        padding-bottom: 4em;
        padding-top: 4em;
    }
`

const _gridBlock = styled.div``

const _topImage = styled.img`
    position: absolute;
    right: -3%;
    top: 0;
`

const _bottomImage = styled.img`
    position: absolute;
    left: -8%;
    top: 65%;
    transform: rotate(-90deg) scaleX(-1);
    opacity: 0.4;
`

export const FullWidthSection: React.FC<FullWidthSectionProps> = ({
    displayTopImage = true,
    displayBottomImage = true,
    children
}) => {
    const appState = React.useContext(AppStateContext)
    return (
        <_fullWidthSection>
            {!appState.isMobile && displayTopImage && <_topImage src={hexagonMini1} />}
            <Grid>
                <_gridBlock>{children}</_gridBlock>
            </Grid>
            {!appState.isMobile && displayBottomImage && <_bottomImage src={hexagonMini1} />}
        </_fullWidthSection>
    )
}
