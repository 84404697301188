import * as React from "react"
import styled from "styled-components"
import ImageBlur from "../../images/v2/image-blur.svg"

interface ImageContainerProps {
    height?: string
    maxImgHeight?: string
}

const _Image = styled.img<ImageContainerProps>`
    position: relative;
    max-width: 100%;

    ${props => (props.maxImgHeight ? `max-height: ${props.maxImgHeight}` : "")};
`

const _imageContainer = styled.div<ImageContainerProps>`
    height: auto;
    border-radius: 25px;
    overflow: hidden;

    @media only screen and (min-width: 992px) {
        height: ${props => (props.height ? props.height : "auto")};
    }

    img {
        padding: 0;
    }
`

const _img = styled.div`
    position: relative;
`

const _blurContainer = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;

    img {
        position: relative;

        height: 100%;
        width: 100%;
    }

    transform: translate(-8%, -15%);
    height: 150%;
    width: 120%;

    @media only screen and (min-width: 992px) {
        height: auto;
        width: auto;

        img {
            position: relative;

            height: unset;
            width: unset;
        }
    }
`

interface ImageWithBlurProps {
    image: string
    height?: string
    className?: string
    isBlured?: boolean
    topOffset?: string
    srcSet?: string
    maxImgHeight?: string
}

export const ImageWithBlur: React.FC<ImageWithBlurProps> = ({
    image,
    isBlured = true,
    height = "330px",
    className,
    topOffset,
    srcSet,
    maxImgHeight
}) => (
    <_img style={{ marginTop: topOffset }}>
        <_imageContainer height={height}>
            <_Image src={image} srcSet={srcSet} className={className} maxImgHeight={maxImgHeight} />
        </_imageContainer>

        {isBlured && (
            <_blurContainer>
                <img src={ImageBlur} />
            </_blurContainer>
        )}
    </_img>
)
