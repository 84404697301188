import * as React from "react"

import { checkIfMobile, getWindowDimensions } from "./responsiveHelper"

type AppState = {
    isMobile: boolean
    isReady: boolean
}

export const AppStateContext = React.createContext<AppState>({
    isMobile: false,
    isReady: false
})

export const PageWrapper: React.FC = p => {
    const [isMobile, setIsMobile] = React.useState<boolean>(checkIfMobile(getWindowDimensions()))
    const [isReady, setIsReady] = React.useState<boolean>(false)

    React.useEffect(() => {
        if (typeof window !== `undefined`) {
            setIsReady(true)
        }

        function handleResize() {
            setIsMobile(checkIfMobile(getWindowDimensions()))
        }

        window.addEventListener("resize", handleResize)
        return () => window.removeEventListener("resize", handleResize)
    })

    return <AppStateContext.Provider value={{ isMobile, isReady }}>{p.children}</AppStateContext.Provider>
}
