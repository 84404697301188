import * as React from "react"

import ReactPaginate from "react-paginate"

interface PaginationProps {
    onPageChange: any
    pageCount: number
}

export const Pagination: React.FC<PaginationProps> = ({ pageCount, onPageChange }) => {
    return (
        <ReactPaginate
            previousLabel={"Previous"}
            pageRangeDisplayed={1}
            marginPagesDisplayed={1}
            nextLabel={"Next"}
            breakLabel={"..."}
            breakClassName={"break-me"}
            pageCount={pageCount}
            onPageChange={onPageChange}
            containerClassName={"pagination"}
            activeClassName={"active"}
        />
    )
}
