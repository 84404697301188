import styled from "styled-components"
import { _description, _h3 } from "../headline.styles"

interface CardContentWrapperProps {
    padding?: string
    align?: string
}

interface CardWrapperProps {
    padding?: number
    margin?: number | string
    marginBottom?: number | string
}

interface ImagePlaceholderProps {
    image: string
}

export const _cardImage = styled.img`
    max-width: 100%;
    max-height: 100%;
`

export const _cardImagePlaceholder = styled.div<ImagePlaceholderProps>`
    height: 200px;
    width: 100%;
    background-image: url(${props => props.image});
    background-size: cover;
    background-position: center center;
`

export const _cardWrapper = styled.div<CardWrapperProps>`
    border-radius: 8px;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.1), 0 0 2px 0 rgba(0, 0, 0, 0.1);
    background-color: var(--white);
    margin: ${props => (props.margin ? props.margin : "0.5")}em;
    margin-bottom: ${props => (props.marginBottom ? props.marginBottom : "2")}em;

    &.leftAlignCard {
        text-align: left;
    }

    &.clickableCardWrapper {
        cursor: pointer;
    }

    &.noBoxShadow {
        box-shadow: none;
    }

    @media only screen and (max-width: 991px) {
        margin-bottom: ${props => (props.marginBottom ? props.marginBottom : "2")}em;
    }
`

export const _cardContentWrapper = styled.div<CardContentWrapperProps>`
    padding: ${props => (props.padding ? props.padding : "1.5")}em;
    text-align: ${props => (props.align ? props.align : "center")};

    @media only screen and (min-width: 992px) {
        min-height: 225px;
    }
`

export const _cardDescription = styled(_description)`
    text-align: inherit;
    font-size: 16px;
    line-height: 1.4;
    color: #646c7a;

    @media only screen and (min-width: 992px) {
        font-size: 16px;
    }

    p {
        margin-top: 0;

        &:last-child {
            margin-bottom: 0;
        }
    }
`

export const _cardTitle = styled(_h3)`
    margin-bottom: 8px;
    text-align: inherit;
    min-height: 95px;
`

export const _cardLink = styled.a`
    font-family: Nunito;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: 0.1px;
    color: var(--warm-blue);
    cursor: pointer;
`
