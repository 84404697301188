import { createGlobalStyle } from "styled-components"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

/** Application-wide default styling. */
export const GlobalStyle = createGlobalStyle`
  :root {
    --warm-blue: #4340de;
    --warm-blue-two: #6967e2;
    --bright-blue-12: rgba(0, 101, 242, 0.12);
    --dark-grey-blue: #2e2e68;
    --cool-green: #2dcb48;
    --jade-green: #28b440;
    --sunflower-yellow: #ffcc00;
    --gold: #e4b80b;
    --vermillion: #f43319;
    --rusty-red: #d12d17;
    --black: #000000;
    --white: #ffffff;
    --dark: #1d253c;
    --slate-grey: #646c7a;
    --very-light-purple: #efedfc;
    --orange-red: #ff3b30;
    --orange-light: #ff8155;
  }

  html {
    box-sizing: border-box;
    height: initial;
    scroll-behavior: smooth;
  }

  *, *:before, *:after {
    box-sizing: inherit;
  }

  * {
    color: var(--slate-grey);
  }

  blockquote {
    margin-inline-start: 0;
    margin-inline-end: 0;
  }

  html, body {
    margin: 0;
    overflow-x: hidden;

    a {
      text-decoration: none;

      color: var(--dark);
      transition: 0s;
      color: var(--dark);

      &:focus {
        outline: 0;
      }

      @media (hover: hover) {
          &:hover {
            border-bottom: 1px solid;
          }
      }
    }
  }

  .LinesEllipsis {
    color: unset;
  }

  .mt-1 {
    margin-top: 1em;
  }

  .mt-2 {
    margin-top: 2em;
  }

  footer {
    width: 100%;
  }

  .slick-prev:before, .slick-next:before {
    content: none !important;
  }

  @media only screen and (max-width: 768px) {
    .Modal {
      padding: 2em !important;
    }
  }

  @media only screen and (max-width: 992px) {
    .slick-slider {
      margin-left: 0em;
      margin-right: 0em;
    }
  }

  .slick-next, .slick-prev {
    height: 32px;
  }

  .slick-slide img {
    margin: auto
  }

  .slick-dots.horizontal li button:before {
    font-size: 12px;
    color: #e1edff;
    opacity: 1;
  }

  .slick-dots.horizontal li.slick-active  button:before {
    color: #4340de;
  }

  .slick-dots.horizontal {
    width: auto;
    height: auto;

    bottom: unset;
    top: 50%;
    transform: translate(0, -50%);
  }

  .slick-dots.horizontal li {
    display: block;
    margin: 10px 5px;
  }

  .slick-dots.mobileWhite li button:before {
    font-size: 12px;
    color: white;
  }

  .slick-dots.mobileWhite li.slick-active  button:before {
    color: white;
  }

  .slick-dots.mobileWhite {
    padding-top: 10px;
    padding-bottom: 10px;
  }


  .slick-dots.mobileBlue li button:before {
    font-size: 10px;
    color: #4340de;
    opacity: 1;
  }

  .slick-dots.mobileBlue li.slick-active  button:before {
    color: #4340de;
    opacity: 1;
    transform: scale(1.7);
  }

  .slick-dots.mobileBlue {
    padding-top: 10px;
    padding-bottom: 10px;
    bottom: -40px;
  }

  .container {
    padding-left: 1.5em;
    padding-right: 1.5em;
  }

  .pagination {
    display: block;
    padding: 0;
    border-radius: 4px;
    border: solid 1px #ebebeb;
    background-color: var(--white);

    li {
      display: inline-block;
      cursor: pointer;

      a {
        display: block;
        height: 100%;
        width: 100%;
        padding: 0.5em 1em;
        border: none;
        display: inline-block;
        font-family: Nunito;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.43;
        letter-spacing: normal;
        color: var(--dark);
      }

      &.active {
        background-color: var(--warm-blue);
        a {
          color: var(--white);
        }
      }
    }
  }
`
