import styled from "styled-components"

interface HeadlineProps {
    fontSize?: number
    fontSizeSm?: number
    align?: string
    letterSpacing?: number
    lineHeight?: number
    marginTop?: number | string
    marginBottom?: number | string
    margin?: number | string
    fontWeight?: string
    color?: string
    maxWidth?: string
    uppercase?: boolean
}

export const _h1 = styled.h1<HeadlineProps>`
    font-family: Poppins;
    text-align: ${props => (props.align ? props.align : "left")};
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    color: ${props => (props.color ? props.color : "var(--dark)")};

    font-size: ${props => (props.fontSizeSm ? props.fontSizeSm : "36")}px;
    line-height: 1.3;
    letter-spacing: -0.64px;
    margin-top: ${props => (props.marginTop ? props.marginTop : "0")}em;
    margin-bottom: ${props => (props.marginBottom ? props.marginBottom : "0")}em;

    @media only screen and (min-width: 992px) {
        letter-spacing: ${props => (props.letterSpacing ? props.letterSpacing : "-1")}px;
        font-size: ${props => (props.fontSize ? props.fontSize : "45")}px;
        line-height: 1.24;
        letter-spacing: -0.8px;
        max-width: ${props => (props.maxWidth ? props.maxWidth : "unset")};
    }

    strong,
    s {
        font-weight: bold;
        color: ${props => (props.color ? props.color : "var(--dark)")};
    }
`

export const _h2 = styled.h2<HeadlineProps>`
    text-align: ${props => (props.align ? props.align : "left")};
    font-family: Poppins;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    color: ${props => (props.color ? props.color : "var(--dark)")};

    font-size: ${props => (props.fontSizeSm ? props.fontSizeSm : "28")}px;
    line-height: 1.3;
    letter-spacing: -0.5px;
    margin: ${props => (props.margin ? props.margin : "unset")};
    margin-top: ${props => (props.marginTop ? props.marginTop : "0")}em;
    margin-bottom: ${props => (props.marginBottom ? props.marginBottom : "0.75")}em;

    @media only screen and (min-width: 992px) {
        text-align: ${props => (props.align ? props.align : "center")};
        font-size: ${props => (props.fontSize ? props.fontSize : "40")}px;
        line-height: 1.2;
        letter-spacing: -0.5px;
        max-width: ${props => (props.maxWidth ? props.maxWidth : "unset")};
    }

    strong,
    s {
        font-weight: bold;
        color: ${props => (props.color ? props.color : "var(--dark)")};
    }
`

export const _h3 = styled.h2<HeadlineProps>`
    font-family: Poppins;
    font-size: ${props => (props.fontSizeSm ? props.fontSizeSm : "24")}px;
    text-align: ${props => (props.align ? props.align : "center")};
    line-height: ${props => (props.lineHeight ? props.lineHeight : "1.33")};
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: -0.1px;
    color: ${props => (props.color ? props.color : "var(--dark)")};

    @media only screen and (min-width: 992px) {
        font-size: ${props => (props.fontSize ? props.fontSize : "24")}px;
    }

    strong {
        font-weight: bold;
        color: ${props => (props.color ? props.color : "var(--dark)")};
    }
`

export const _description = styled.figure<HeadlineProps>`
    font-family: Nunito;
    font-weight: ${props => (props.fontWeight ? props.fontWeight : "normal")};
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: 0.1px;
    color: ${props => (props.color ? props.color : "var(--dark)")};
    margin: 0;
    text-align: ${props => (props.align ? props.align : "left")};

    font-size: 14px;

    @media only screen and (min-width: 992px) {
        font-size: ${props => (props.fontSize ? props.fontSize : "16")}px;
    }

    ${props => (props.uppercase ? "text-transform: uppercase" : "")};
`

export const _h2LineThrough = styled(_h2)`
    s {
        text-decoration-color: var(--orange-light);
    }
`

export const _h2ParagraphNoMargin = styled(_h2)`
    p {
        margin: 0;
    }
`
