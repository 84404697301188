import { SliderArrow } from "./"
import React from "react"

export const SliderSettings = ({
    slidesToShow = 1,
    slidesToScroll = 1,
    arrows = true,
    extraDotsClass = "",
    dots = true,
    centerMode = false,
}) => {
    return {
        dots: dots,
        infinite: true,
        speed: 500,
        slidesToShow,
        arrows: arrows,
        prevArrow: <SliderArrow to="prev" />,
        nextArrow: <SliderArrow to="next" />,
        slidesToScroll,
        centerMode,
        dotsClass: `slick-dots ${extraDotsClass}`
    }
}
