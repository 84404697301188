import styled from "styled-components"
import React from "react"
import { Link } from "gatsby"

export const _ImageLink = styled(props => <Link {...props} />)`
    cursor: pointer;

    img {
        transition: 0.2s;
    }

    @media (hover: hover) {
        &:hover {
            border: none;
            img {
                transform: scale(1.1);
            }
        }
    }
`

export const _NavLink = styled(props => <Link {...props} />)`
    font-family: Nunito;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: 0.1px;
    color: var(--dark);
    transition: 0.2s;
    cursor: pointer;

    &.active {
        color: var(--warm-blue);
    }
`

export const _ExternalNavLink = styled.a`
    font-family: Nunito;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: 0.1px;
    color: var(--dark);
    transition: 0.2s;
    cursor: pointer;

    &.active {
        color: var(--warm-blue);
    }

    &.loginButton {
        font-weight: bold;
        color: var(--warm-blue);
    }
`

export const _SecondNavLink = styled(_NavLink)`
    font-size: 14px;
    line-height: 1.43;
    letter-spacing: normal;
    color: var(--slate-grey);
`

export const _processLink = styled(props => <Link {...props} />)`
    font-family: Nunito;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: 0.1px;
    color: var(--warm-blue);
`

export const _uppercaseLink = styled(props => <Link {...props} />)`
    font-family: Poppins;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.8px;
    text-align: center;
    color: ${props => (props.color ? props.color : "#5c2060")};
    font-size: ${props => (props.fontSize ? props.fontSize : 14)}px;
    font-weight: ${props => (props.fontWeight ? props.fontWeight : 500)};
    text-transform: uppercase;
    border-bottom: 1px solid #5c2060;
`

export const _processExternalLink = styled.a`
    font-family: Nunito;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: 0.1px;
    color: var(--warm-blue);
    text-transform: uppercase;
    text-decoration: underline;
`

export interface DropdownLink {
    content: string
    href: string
}

interface DropdownProps {
    title: string
    links?: DropdownLink[]
}

const _DropdownWrapper = styled.div`
    position: relative;
`

const _FakeNavLink = styled.span`
    font-family: Nunito;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: 0.1px;
    color: var(--dark);
    transition: 0.2s;
    cursor: pointer;

    &.active {
        color: var(--warm-blue);
    }
`
const _DropdownNavLink = styled(_FakeNavLink)`
    transform: translate(-5px, 0px);

    :after {
        content: "";
        display: inline-block;
        width: 0;
        height: 0;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-top: 7px solid #000;
        position: absolute;
        top: 50%;
        transform: translate(50%, -50%);
    }
`

const _DropdownMenu = styled.div`
    display: none;
    background-color: white;
    padding: 1em;
    position: absolute;
    top: 100%;
    transform: translate(-25%, 10px);
    left: 50%;
    width: 150px;
    text-align: left;
    box-shadow: 0px 1px 5px 0px rgba(202, 202, 202, 0.75);

    &.active {
        display: block;
    }
`

const _DropdownMenuList = styled.ul`
    padding: 0;
    display: block;

    li {
        display: block;
        margin-bottom: 0.2em;

        a {
            font-size: 14px;
        }
    }
`

const _DropdownLink = styled(_NavLink)`
    display: block;

    &:hover {
        border-bottom: none;
    }
`

export const Dropdown: React.FC<DropdownProps> = ({ title, links }) => {
    const [showMenu, setShowMenu] = React.useState(false)

    let dropdownMenuList

    if (links && links.length > 0) {
        dropdownMenuList = (
            <_DropdownMenuList>
                {links.map((v: DropdownLink, index) => (
                    <li key={index}>
                        <_DropdownLink key={v.href} to={v.href}>
                            {v.content}
                        </_DropdownLink>
                    </li>
                ))}
            </_DropdownMenuList>
        )
    }

    return (
        <_DropdownWrapper>
            <_DropdownNavLink
                onClick={e => {
                    e.stopPropagation()
                    e.nativeEvent.stopImmediatePropagation()
                    setShowMenu(!showMenu)
                }}>
                {title}
            </_DropdownNavLink>

            <_DropdownMenu className={showMenu ? "active" : ""}>{dropdownMenuList}</_DropdownMenu>
        </_DropdownWrapper>
    )
}
