import React from "react"

export const useReadyState = () => {
    const [ready, setReady] = React.useState(false)

    React.useEffect(() => {
        if (typeof window !== `undefined`) {
            setReady(true)
        }
    }, [])
    return ready
}
