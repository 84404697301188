import * as React from "react"
import styled from "styled-components"
import { Link } from "gatsby"

interface ButtonProps {
    disabled?: boolean
    marginTop?: number
    marginBottom?: number
    marginLeft?: number
    marginRight?: number
}

interface LinkButtonProps {
    disabled?: boolean
    href: string
    marginTop?: number
    marginBottom?: number
    marginLeft?: number
    marginRight?: number
}

export const _button = styled.button<ButtonProps>`
    border: none;
    height: 48px;
    border-radius: 24px;
    background-color: var(--warm-blue);
    transition: 0.15s;

    font-family: Nunito;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: 0.1px;
    text-align: center;
    color: var(--white);
    padding: 5px 20px;

    margin-top: ${props => (props.marginTop ? props.marginTop : "0")}em;
    margin-bottom: ${props => (props.marginBottom ? props.marginBottom : "0")}em;
    margin-left: ${props => (props.marginLeft ? props.marginLeft : "0")}em;
    margin-right: ${props => (props.marginRight ? props.marginRight : "0")}em;

    opacity: ${props => (props.disabled ? "0.75" : "1")};
    @media (hover: hover) {
        &:hover {
            background: var(--warm-blue-two);
            cursor: ${props => (props.disabled ? "default" : "pointer")};
        }
    }
    :focus {
        border: none;
        outline: none;
    }
`

export const _buttonBlock = styled(_button)`
    width: 100%;
    display: block;
`

export const _linkButton = styled(props => <Link {...props} />)`
    border: none;
    border-radius: 24px;
    background-color: var(--warm-blue);
    transition: 0.15s;
    font-family: Nunito;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: 0.1px;
    text-align: center;
    color: var(--white);
    padding: 12px 39px;

    display: inline-block;

    opacity: ${props => (props.disabled ? "0.75" : "1")};
    @media (hover: hover) {
        &:hover {
            background: var(--warm-blue-two);
            cursor: ${props => (props.disabled ? "default" : "pointer")};
            border-bottom: none;
        }
    }
    :focus {
        border: none;
        outline: none;
    }

    &.rightAlign {
        margin-left: auto;
    }
`

export const _linkBlockButton = styled(_linkButton)`
    display: block;
    width: 100%;
    margin: 1em 0em;
`

export const _alternativeButton = styled(_button)<ButtonProps>`
    background-color: var(--white);
    color: var(--warm-blue);

    @media (hover: hover) {
        &:hover {
            background: var(--warm-blue-two);
            cursor: ${props => (props.disabled ? "default" : "pointer")};
        }
    }
`

export const _externalLinkButton = styled.a<LinkButtonProps>`
    border: none;
    border-radius: 24px;
    background-color: var(--warm-blue);
    transition: 0.15s;
    font-family: Nunito;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: 0.1px;
    text-align: center;
    color: var(--white);
    padding: 12px 39px;

    display: block;
    width: 100%;
    margin: 2em 0;

    margin-top: ${props => (props.marginTop ? props.marginTop : "2")}em;
    margin-bottom: ${props => (props.marginBottom ? props.marginBottom : "2")}em;
    margin-left: ${props => (props.marginLeft ? props.marginLeft : "0")}em;
    margin-right: ${props => (props.marginRight ? props.marginRight : "0")}em;

    @media only screen and (min-width: 992px) {
        display: inline-block;

        margin-top: ${props => (props.marginTop ? props.marginTop : "0")}em;
        margin-bottom: ${props => (props.marginBottom ? props.marginBottom : "0")}em;
        margin-left: ${props => (props.marginLeft ? props.marginLeft : "0")}em;
        margin-right: ${props => (props.marginRight ? props.marginRight : "0")}em;

        width: auto;
    }

    opacity: ${props => (props.disabled ? "0.75" : "1")};
    @media (hover: hover) {
        &:hover {
            background: var(--warm-blue-two);
            cursor: ${props => (props.disabled ? "default" : "pointer")};
            border-bottom: none;
        }
    }
    :focus {
        border: none;
        outline: none;
    }
`

export const _externalWhiteLinkButton = styled(_externalLinkButton)<LinkButtonProps>`
    opacity: 1;
    background-color: var(--white);
    color: var(--warm-blue);

    @media (hover: hover) {
        &:hover {
            background-color: var(--white);
            opacity: 0.8;
        }
    }
`
