import * as React from "react"
import { Navigation } from "./Navigation"
import { Footer } from "."
import { GlobalStyle, em } from "../../styles"
import { _SectionNoMargin, _OverflowHiddenSectionNoMargin } from "../shared"
import { useSiteMetadata } from "../../hooks"
import { Row, Grid } from "react-flexbox-grid"
import { navigationLinks, NavigationLinkProps } from "../../links"
import { extractPrismicNode } from "../../utils/prismicHelper"
import { StaticQuery, graphql } from "gatsby"

export const layoutQuery = graphql`
    {
        prismic {
            ...navigationLinksFragment
        }
    }
`

export const Layout: React.FC<LayoutProps> = ({ children, navLinks, mobileNavLinks, navbarImage }) => {
    return (
        <StaticQuery
            query={layoutQuery}
            render={data => (
                <LayoutComponent
                    navLinks={navLinks}
                    mobileNavLinks={mobileNavLinks}
                    navbarImage={navbarImage}
                    data={data}
                >{children}</LayoutComponent>
            )}
        />
    )
}

type LayoutProps = {
    navLinks?: NavigationLinkProps[]
    navbarImage?: JSX.Element
    mobileNavLinks?: NavigationLinkProps[]
    data?: any
}

export const LayoutComponent: React.FC<LayoutProps> = ({ data, children, navLinks, mobileNavLinks, navbarImage }) => {
    // Required check for no data being returned
    const doc = extractPrismicNode(data, "allEvents")
    if (!doc) return null

    const { title } = useSiteMetadata()
    return (
        <_OverflowHiddenSectionNoMargin>
            <Row>
                <Navigation
                    navLinks={
                        navLinks ||
                        navigationLinks(title, false, {
                            href: doc?.event_link?.url,
                            isVisible: doc.events_is_visible
                        })
                    }
                    mobileNavLinks={
                        mobileNavLinks ||
                        navigationLinks(title, false, {
                            href: doc?.event_link?.url,
                            isVisible: doc.events_is_visible
                        })
                    }
                    navbarImage={navbarImage}
                />
            </Row>

            <Grid style={{ paddingTop: em(4) }}>{children}</Grid>

            <Row>
                <Footer />
            </Row>
            <GlobalStyle />
        </_OverflowHiddenSectionNoMargin>
    )
}
