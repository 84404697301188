import * as React from "react"
import { _h2, _h3, _description } from "../headline.styles"
import { _Blockquote } from "../blockquote.styles"
import { _cardWrapper, _cardImage, _cardContentWrapper, _cardDescription, _cardTitle, _cardLink } from "./card.styles"
import { EmbededVideo } from "../EmbededVideo"

interface VideoCardProps {
    className?: string
    videoUrl: string
}

export const VideoCard: React.FC<VideoCardProps> = ({ videoUrl, className }) => {
    return (
        <_cardWrapper margin={0} className={className ? className : ""}>
            <_cardContentWrapper padding={"0"}>
                <EmbededVideo videoSrcURL={videoUrl} videoTitle="Title" />
            </_cardContentWrapper>
        </_cardWrapper>
    )
}
